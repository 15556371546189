import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/grid/emotion'

import {
  breakpoints,
  colors,
  fontColor,
  fontWeights,
  borderColors,
  layers,
  paletteText,
} from '@styles/01-settings'
import { bp, breakpoint } from '@styles/02-tools/tools.breakpoints'
import { boxShadowDefault } from '@styles/02-tools/tools.shadows'
import { rem, transition } from '@styles/02-tools/tools.common'
import {
  oWrapperOuter,
  oWrapperInner,
} from '@styles/05-objects/objects.wrapper'

export const keyFramesFadeUp = () => `
  @keyframes fadeUp {
    to {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
`

export const keyFramesShowIn = () => `
@keyframes showIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}
`

export const StyledHeaderOuter = styled.header`
  ${oWrapperOuter}
  max-width: 100vw;
  position: fixed;
  width: 100%;
  opacity: 0;
  background-color: ${paletteText.secondary_background};
  color: ${colors.base.white};
  padding: 0.334rem 10vw;
  z-index: ${layers.layer_4};
  transition: ${transition('transform')};
  animation: opacityTransition 250ms ease-out forwards;
  @keyframes opacityTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.shadow {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(-100%);
  }

  ${breakpoint.gt('md')`padding: 1.334rem 10vw;`}
`

export const WrapperFlex = styled(Flex)`
  ${oWrapperInner}
`

export const ButtonGroupFlex = styled(Flex)`
  display: none;
  ${breakpoint.gt('md')`display: flex;`}
`

export const StyledIcon = styled.div`
  z-index: 99;
  cursor: pointer;

  svg {
    width: 3.33rem;
    height: 3.33rem;
  }
`

export const MenuIcon = styled(StyledIcon)``

export const AdditionalMobileItems = styled(Box)`
  z-index: ${layers.layer_5};
`

export const NavBox = styled(Box)`
  display: flex;
  position: fixed;
  overflow-y: scroll;
  height: 1px;
  width: 1px;
  left: -100%;
  opacity: 0;

  transition: opacity 250ms ease;

  &.is-menu-open {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: ${paletteText.secondary_background};

    min-height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 0.97;
  }
`

export const StyledNavigation = styled.ul`
  list-style: none;
  margin-bottom: 1.625rem;
  padding: 0;

  > li {
    margin-bottom: 1.625rem;
  }
`

export const StyledNavigationItem = styled.li`
  position: relative;
  text-align: center;

  a,
  a > p {
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: ${fontWeights.light};
    color: ${paletteText.secondary_text};

    &:hover {
      text-decoration: none;
    }
  }
`

export const SocialLinksBox = styled.div`
  * {
    display: inline-block;
  }

  .social-link {
    color: ${paletteText.text};
    display: inline-block;
    margin-right: 1rem;
  }

  .gatsby-image-wrapper {
    filter: invert(1);
    height: 1.5rem;
    width: 1.5rem;
  }
`

export const StyledAnchor = styled.a``
export const CompanyNameBox = styled(Box)`
  z-index: 99;
  a {
    font-size: 1.5rem;
    font-weight: ${fontWeights.light};
    color: ${paletteText.secondary_text};

    &:hover {
      text-decoration: none;
    }
  }
`
