import React from 'react'

import { PrismicLinkObj, PrismicTextObj } from '@models/common.model'

import { ContainerBox } from '@components/container'
import Link from 'gatsby-link'

import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { PrismicImage } from '@models/image.models'
import { Box, Flex } from '@rebass/grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  CompanyBox,
  CopyrightBox,
  FooterFlex,
  FooterMenuList,
  LeftFooterBox,
  RightFooterFlex,
  SocialBox,
} from './style'
import { linkResolver } from '@prismic/linkResolverHelper'
import { reduce } from 'lodash'
import { paletteText } from '@styles/01-settings'
import { TrailFadeUp } from '@utils/animations'

interface PrismicFooterSocialItem {
  social_icon: PrismicImage
  social_link: PrismicLinkObj
}

interface PrismicFooterMenuItem {
  page_title_link: string
  page_link: PrismicLinkObj
}

interface PrismicFooterProps {
  data: {
    data: {
      section_headline: PrismicTextObj
      hq_company: PrismicTextObj
      copyright_text: PrismicTextObj
      page_links: PrismicFooterMenuItem[]
      social_label: string
      social_links: PrismicFooterSocialItem[]
    }
  }
}

export const Footer: React.FC<PrismicFooterProps> = React.memo(props => {
  if (!props?.data?.data) {
    return <></>
  }
  const { data } = props.data

  return (
    <ContainerBox
      as="footer"
      className={'c-footer has-light-background'}
      outerAttributes={{ backgroundColor: paletteText.background }}
    >
      <TrailFadeUp>
        {/* <FooterMenuList as="ul">
          {data.page_links.map((item, index) => (
            <li key={`page-link-${index}`}>
              <Link to={item.page_link.url} target={item.page_link.target}>
                {item.page_title_link}
              </Link>
            </li>
          ))}
        </FooterMenuList> */}
        <FooterFlex justifyContent="space-between" pb="4.125rem">
          <LeftFooterBox>
            <RichText
              render={data.section_headline.raw}
              linkResolver={linkResolver}
            />
          </LeftFooterBox>
          <RightFooterFlex>
            {/* <CompanyBox>
              <RichText
                render={data.hq_company.raw}
                linkResolver={linkResolver}
              />
            </CompanyBox> */}
            <SocialBox>

              {data.social_links.map((item, index) => (
                <a
                  className="social-link"
                  key={`social-link-${index}`}
                  href={item.social_link.url}
                  target={item.social_link.target}
                >
                  <GatsbyImage
                    image={item?.social_icon?.gatsbyImageData}
                    alt="social icon"
                  />
                </a>
              ))}
            </SocialBox>
          </RightFooterFlex>
        </FooterFlex>
        <CopyrightBox>
          <RichText
            render={data.copyright_text.raw}
            linkResolver={linkResolver}
          />
        </CopyrightBox>
      </TrailFadeUp>
    </ContainerBox>
  )
})

Footer.displayName = 'Footer'

export const query = graphql`
  fragment footer on PrismicFooter {
    data {
      section_headline {
        raw
      }
      page_links {
        page_title_link
        page_link {
          url
          uid
          target
        }
      }
      hq_company {
        raw
      }
      copyright_text {
        raw
      }
      social_label
      social_links {
        social_icon {
          gatsbyImageData(placeholder: NONE)
        }
        social_link {
          url
          uid
          target
        }
      }
    }
  }
`
