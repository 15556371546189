import { ThemePallettes } from './theme-pallettes'

import { pageTheme } from './page-theme'

export const themes = (theme: keyof ThemePallettes) => {
  switch (theme) {
    default:
      return pageTheme(theme)
  }
}
