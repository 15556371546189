export const linkResolver = (doc) => {
  switch (doc.type) {
    case 'homepage':
      return `/`
    case 'project_post': {
      // Post should have relationship to archive page
      const hasRelationship =
        typeof doc.data === 'object' && doc.data.relationship.id

      if (hasRelationship) {
        return `${doc.data.relationship.uid}/${doc.uid}/`
      }
      // fallback
      return `projekty/${doc.uid}/`
    }
    default:
      return `/${doc.uid || ''}`
  }
}
