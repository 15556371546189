import React, { ReactNode } from 'react'

import { ObjProps } from '@models/common.model'

import {
  StyledContainerFlexInner,
  StyledContainerBoxInner,
  StyledLargeContainerBox,
  StyledLargeContainerFlex,
  StyledContainerBox,
  StyledLightContainerBox,
  StyledSmallContainerFlexInner,
} from './style'

type ContainerProps = {
  children: ReactNode
  outerAttributes?: ObjProps<string | (string | undefined)[]>
  innerAttributes?: ObjProps<string | (string | undefined)[]>
  className?: string
  as?: React.ElementType
}

export const SmallContainerFlex: React.FC<ContainerProps> = props => {
  return (
    <StyledContainerBox
      as={props.as || 'section'}
      {...props.outerAttributes}
      className={props.className}
    >
      <StyledSmallContainerFlexInner {...props.innerAttributes}>
        {props.children}
      </StyledSmallContainerFlexInner>
    </StyledContainerBox>
  )
}

export const ContainerFlex: React.FC<ContainerProps> = props => {
  return (
    <StyledContainerBox
      as={props.as || 'section'}
      {...props.outerAttributes}
      className={props.className}
    >
      <StyledContainerFlexInner {...props.innerAttributes}>
        {props.children}
      </StyledContainerFlexInner>
    </StyledContainerBox>
  )
}

export const FullwidthContainerFlex: React.FC<ContainerProps> = props => {
  return (
    <StyledLargeContainerFlex
      as={props.as || 'section'}
      {...props.innerAttributes}
      className={props.className}
    >
      {props.children}
    </StyledLargeContainerFlex>
  )
}

export const ContainerBox: React.FC<ContainerProps> = props => {
  return (
    <StyledContainerBox
      as={props.as || 'section'}
      {...props.outerAttributes}
      className={props.className}
    >
      <StyledContainerBoxInner {...props.innerAttributes}>
        {props.children}
      </StyledContainerBoxInner>
    </StyledContainerBox>
  )
}

export const LightContainerBox: React.FC<ContainerProps> = props => {
  return (
    <StyledLightContainerBox
      as={props.as || 'section'}
      {...props.outerAttributes}
      className={props.className}
    >
      <StyledContainerBoxInner {...props.innerAttributes}>
        {props.children}
      </StyledContainerBoxInner>
    </StyledLightContainerBox>
  )
}

export const FullwidthContainerBox: React.FC<ContainerProps> = props => {
  return (
    <StyledLargeContainerBox
      as={props.as || 'section'}
      {...props.innerAttributes}
      className={props.className}
    >
      {props.children}
    </StyledLargeContainerBox>
  )
}
