import React, {
  ReactNode,
  Suspense,
  lazy,
  useContext,
  ReactElement,
  useState,
  useEffect,
} from 'react'
import { ThemeProvider } from '@emotion/react'
    import { navigate } from '@reach/router'

import { PrismicSeo } from '@models/seo.models'
import { PrismicHeader } from '@models/header.models'
import { PrismicMetaDataWithLanguages } from '@models/meta-data.models'

import { PageHelmet } from '@components/page-helmet'
import { breakpoints } from '@styles/01-settings'
import { ThemePallettes } from '@styles/99-themes/theme-pallettes'
import { StyledMain } from './style'
import { Loader } from '@components/loader'
import { componentDidMount } from '@utils/lifecycles'

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@context/global-context-provider'
import { Footer } from '@components/footer'
import Header from '@components/header'

/**
 * @description Used to overwrite default breakpoints provided by @rebass/grid
 */
const defaultWebsiteTheme = {
  breakpoints: Object.values(breakpoints).map(breakpoint => breakpoint + 'px'),
}

type LayoutProps = {
  activeMetaData?: PrismicMetaDataWithLanguages
  seo?: PrismicSeo
  header?: PrismicHeader
  children: ReactNode
  footer?: any
  theme?: keyof ThemePallettes
  hero?: ReactElement
  staticHeader?: boolean
}

export const Layout: React.FC<LayoutProps> = props => {
  const {
    activeMetaData,
    seo,
    header,
    hero,
    children,
    footer,
    theme,
    staticHeader,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isSSR = typeof window === 'undefined'
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext) as any

  useEffect(() => {
    const hash = window.location.hash || ''

    setTimeout(() => {
      setIsLoading(false)
      if (hash && hash !== '') {
        navigate(hash)
      }
    }, 200)
  }, [400])

  return (
    <ThemeProvider theme={defaultWebsiteTheme}>
      {seo && (
        <PageHelmet
          title={seo?.data?.meta_title?.text}
          description={seo?.data?.meta_description?.text}
          socialImage={seo?.data?.open_graph_image?.url}
          lang={activeMetaData?.lang}
          pageUrl={activeMetaData?.url}
        />
      )}

      {!isSSR && (
        <Suspense fallback={null}>
          <Header
            props={{ header }}
            theme={theme}
            staticHeader={staticHeader}
          />
          <StyledMain pageTheme={theme}>
            {hero && hero}
            {children}
          </StyledMain>
          <Footer data={footer} />
        </Suspense>
      )}
    </ThemeProvider>
  )
}
