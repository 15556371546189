import React, { lazy } from 'react'

import { ThemePallettes } from '@styles/99-themes/theme-pallettes'

const PinnedPortfollioSlice = lazy(() => import('@slices/pinned-portfolio'))
const ContentWithImageSlice = lazy(() => import('@slices/content-with-image'))
const ListOfServicesSlice = lazy(() => import('@slices/list-of-services'))
const ReferencesSlice = lazy(() => import('@slices/references'))
const HeroSlice = lazy(() => import('@slices/hero'))
const HeroWithImageSlice = lazy(() => import('@slices/hero-with-image'))
const HeadlineSlice = lazy(() => import('@slices/headline'))
const ContentSlice = lazy(() => import('@slices/content'))
const ContactFormSlice = lazy(() => import('@slices/form'))
const ProblemOutcomeSlice = lazy(() => import('@slices/problem-outcome'))
const NextPostLink = lazy(() => import('@slices/next-post-link'))

type SliceProps = {
  allSlices: any
  theme?: keyof ThemePallettes
}

export const Slice: React.FC<SliceProps> = ({ allSlices, theme }) =>
  allSlices
    ? allSlices.map((slice: any) => {
        switch (slice?.slice_type) {
          case 'hero':
            return <HeroSlice key={slice.id} props={slice} />
          case 'hero_with_image':
            return <HeroWithImageSlice key={slice.id} props={slice} />
          case 'content_with_image':
            return <ContentWithImageSlice key={slice.id} props={slice} />
          case 'pinned_portfolio':
            return <PinnedPortfollioSlice key={slice.id} props={slice} />
          case 'list_of_services':
            return <ListOfServicesSlice key={slice.id} props={slice} />
          case 'references':
            return <ReferencesSlice key={slice.id} props={slice} />
          case 'headline':
            return <HeadlineSlice key={slice.id} props={slice} />
          case 'content':
            return <ContentSlice key={slice.id} props={slice} />
          case 'contact_form':
            return <ContactFormSlice key={slice.id} props={slice} />
          case 'problem_-__solution_-__outcome':
            return <ProblemOutcomeSlice key={slice.id} props={slice} />
          case 'next_post_link':
            return <NextPostLink key={slice.id} props={slice} />
          default:
            return null
        }
      })
    : null
