/**
 * SEO / Page Helmet component
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { GenerateMetaTags } from './meta-tags'

export type PageHelmetProps = {
  description?: string
  lang?: string
  meta?: []
  title: string
  pageUrl?: string
  socialImage?: string
}

export const PageHelmet = (props: PageHelmetProps) => {
  const queryResult = useStaticQuery(
    graphql`
      {
        image_en: file(relativePath: { eq: "images/site_share_demo_en.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FIXED)
          }
        }
        image_pl: file(relativePath: { eq: "images/site_share_demo_pl.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FIXED)
          }
        }
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  )

  const { site } = queryResult

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang === 'pl' ? 'pl-PL' : 'en-US',
      }}
      title={props.title || 'Lukarna Studio'}
    >
      {GenerateMetaTags(props, queryResult)}
      <link
        rel="canonical"
        href={`${site.siteMetadata.siteUrl}${props.pageUrl}`}
        data-baseprotocol="https:"
        data-basehost={`${site.siteMetadata.siteUrl}`}
      />
    </Helmet>
  )
}
