import { ThemePallettes } from './theme-pallettes'
import { customLabelsPrismic } from './shared'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'

export const pageTheme = (theme: keyof ThemePallettes) => `
  ${customLabelsPrismic(theme)}

  section:first-of-type.c-subsection-title {
      padding-top: 4.125rem;
      margin-bottom: -4.125rem;

      ${breakpoint.gt('md')`
        padding-top: 8.125rem;
        margin-bottom: -8.125rem;
      `}
  }

  > section {
    &:not(.c-hero):not(.c-subsection-title) {
      padding-top: 4.125rem;
      padding-bottom: 4.125rem;

      ${breakpoint.gt('md')`
        padding-top: 8.125rem;
        padding-bottom: 8.125rem;
      `}
    }
  }
`
