import { Link } from 'gatsby'
import React, { useState, ReactNode, memo, useContext } from 'react'
import { Flex, Box } from '@rebass/grid/emotion'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import { useWindowScroll, VISIBLE_AREA_HEIGHT } from '@hooks/useDocumentScroll'

import CloseOutline from '@svg/close-outline.svg'
import ReorderTwoOutline from '@svg/reorder-two-outline.svg'
import { ThemePallettes } from '@styles/99-themes/theme-pallettes'

import {
  PrismicHeader,
  NavigationItem,
  PrismicHeaderLogo,
} from '@models/header.models'
import { PrismicLinkWithLabel } from '@models/link.models'
import { PrismicMetaData } from '@models/meta-data.models'

import {
  WrapperFlex,
  StyledHeaderOuter,
  NavBox,
  MenuIcon,
  AdditionalMobileItems,
  StyledNavigationItem,
  StyledNavigation,
  StyledIcon,
  CompanyNameBox,
  SocialLinksBox,
} from './style'
import { GlobalStateContext } from '@context/global-context-provider'
import { graphql } from 'gatsby'
import { PrismicTextObj } from '@models/common.model'
import { RichText } from 'prismic-reactjs'
import { TrailFadeUp } from '@utils/animations'
import { SocialBox } from '@components/footer/style'
import { GatsbyImage } from 'gatsby-plugin-image'

export type HeaderProps = {
  props: any
  theme?: keyof ThemePallettes
  staticHeader?: boolean
  label: PrismicTextObj
  socialLinks: any
}

const isLinkExternal = (link: PrismicMetaData | undefined) =>
  link?.url.indexOf('http') !== -1

const isAnchorTag = (link: PrismicMetaData | undefined) =>
  link?.url.indexOf('#') !== -1

function navigationItemLink(
  item: any,
  index?: number,
  toggleMenuFn?: () => void,
): ReactNode {
  if (item?.link?.url) {
    return (
      <Link
        className="custom-link"
        to={item.link.url}
        target={item.link.target}
        onClick={toggleMenuFn}
      >
        <RichText render={item.label.raw} />
      </Link>
    )
  }
  if (item?.anchor_tag) {
    return (
      <Link
        className="custom-link"
        to={`/${item?.anchor_tag || ''}`}
        onClick={toggleMenuFn}
      >
        <RichText render={item.label.raw} />
      </Link>
    )
  }
}

function navigationElement(
  navigation: NavigationItem[],
  toggleMenuFn: any,
  showMenu?: boolean,
): ReactNode {
  const breakpoint = useBreakpoint()
  const [showSubMenu, setShowSubMenu] = useState<number | null>(null)

  const renderedNavigation = showMenu
    ? navigation.map((item, index) => {
        if (item.primary.label.text) {
          return (
            <StyledNavigationItem
              className={
                !breakpoint.is_gt_md && showSubMenu === index
                  ? 'is-submenu-open'
                  : ''
              }
              key={`nav-${index}`}
            >
              {!!item.primary.label.text &&
                navigationItemLink(item.primary, index, toggleMenuFn)}
            </StyledNavigationItem>
          )
        }
      })
    : null

  return <TrailFadeUp>{renderedNavigation}</TrailFadeUp>
}

function buttonElement(
  button: PrismicLinkWithLabel,
  usage = 'primary',
  className = '',
  withArrow = false,
): ReactNode {
  return (
    <Link to={button.link?.url} target={button.link?.target}>
      {button.label.text}
    </Link>
  )
}

function logoImageElement(logo?: PrismicHeaderLogo): ReactNode {
  return (
    <CompanyNameBox style={{ minWidth: '12.7rem' }}>
      <Link className="custom-link" aria-label="Lukarna Homepage" to="/">
        LUKARNA STUDIO
      </Link>
    </CompanyNameBox>
  )
}

export const Header: React.FC<HeaderProps> = memo(
  ({ props, theme, staticHeader }) => {
    const breakpoint = useBreakpoint()
    const state = useContext(GlobalStateContext)
    const header = props?.header?.data
    const navigation = header?.nav
    const socialLinks = props?.header?.socialLinks

    const [menuHovered, setMenuHovered] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
      setShowMenu(!showMenu)
    }

    return (
      <>
        <StyledHeaderOuter className={`js-navigation`}>
          <WrapperFlex justifyContent="space-between" alignItems="center">
            {logoImageElement(theme)}

            <MenuIcon onClick={toggleMenu}>
              {showMenu ? <CloseOutline /> : <ReorderTwoOutline />}
            </MenuIcon>

            <NavBox
              as="nav"
              className={showMenu ? 'is-menu-open' : ''}
              onMouseEnter={() => setMenuHovered(true)}
              onMouseLeave={() => setMenuHovered(false)}
            >
              <StyledNavigation>
                {navigation &&
                  navigationElement(navigation, toggleMenu, showMenu)}
              </StyledNavigation>
              {showMenu && (
                <SocialLinksBox>
                  <TrailFadeUp delay="800">
                    {socialLinks.map((item, index) => (
                      <a
                        className="social-link"
                        key={`header-social-link-${index}`}
                        href={item.social_link.url}
                        target={item.social_link.target}
                      >
                        <GatsbyImage
                          image={item?.social_icon?.gatsbyImageData}
                          alt="social icon"
                        />
                      </a>
                    ))}
                  </TrailFadeUp>
                </SocialLinksBox>
              )}
            </NavBox>
          </WrapperFlex>
        </StyledHeaderOuter>
      </>
    )
  },
)

Header.displayName = 'Header'

export const query = graphql`
  fragment header on PrismicHeader {
    data {
      nav {
        ... on PrismicHeaderDataNavNavItem {
          id
          primary {
            link {
              url
              uid
              target
              type
            }
            label {
              text
              raw
            }
            anchor_tag
          }
        }
      }
      logo {
        localFile {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
      company_name
    }
  }
`
