import { Flex, Box } from '@rebass/grid/emotion'
import styled from '@emotion/styled'

import {
  oWrapperOuter,
  oWrapperInner,
  oLargeWrapperInner,
  oSmallWrapperInner,
} from '@styles/05-objects/objects.wrapper'
import { paletteText } from '@styles/01-settings'

export const StyledContainerBox = styled(Box)`
  ${oWrapperOuter}
`
export const StyledContainerBoxInner = styled(Box)`
  ${oWrapperInner}
`

export const StyledLightContainerBox = styled(Box)`
  background-color: ${paletteText.background};
  ${oWrapperInner}
`

export const StyledSmallContainerFlexInner = styled(Flex)`
  ${oSmallWrapperInner}
`

export const StyledLargeContainerBox = styled(Box)`
  ${oLargeWrapperInner}
`
export const StyledContainerFlexInner = styled(Flex)`
  ${oWrapperInner}
`

export const StyledLargeContainerFlex = styled(Flex)`
  ${oLargeWrapperInner}
`
