import { Box, Flex } from '@rebass/grid'
import { paletteText } from '@styles/01-settings'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import styled from 'styled-components'

export const StyledFooter = styled.div`
  background-color: ${paletteText.background};
`

export const CopyrightBox = styled(Box)`
  p {
    font-size: 0.875rem;
  }

  a {
    font-size: 0.875rem;
    color: ${paletteText.text};
  }
`

export const FooterMenuList = styled(Box)`
  margin: 0 0 3rem;
  padding: 0;

  li {
    &:not(:last-of-type) {
      margin-right: 4.35rem;
    }
    display: inline-block;

    a {
      text-transform: uppercase;
      color: ${paletteText.text};
    }
  }

  ${breakpoint.gt('md')`
  text-align: right;
  li {
    &:not(:last-of-type) {
      margin-right: 4.35rem;
    }
  }
`}
`

export const SocialBox = styled(Box)`
  .social-link {
    color: ${paletteText.text};
    display: inline-block;
    margin-right: 1rem;
  }

  .gatsby-image-wrapper {
    height: 1.5rem;
    width: 1.5rem;
  }
`

export const FooterFlex = styled(Flex)`
  flex-direction: column;

  align-items: center;
`

export const LeftFooterBox = styled(Box)`
  a {
    color: ${paletteText.text};
  }

  margin-bottom: 3rem;

  a {
    &:after {
      content: none;

      .link.with.arrow {
        content: none;
      }
    }

    padding: 13px 12px;
    border: 1px solid #333;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 3px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #333;
      border: 1px solid #333;
      color: #fefefe;
      border-radius: 30px;
    }
  }
`

export const RightFooterFlex = styled(Flex)`
  flex-direction: column;

  ${breakpoint.gt('md')`flex-direction: row; margin-top: 1rem;`}
`

export const CompanyBox = styled(Box)`
  margin-bottom: 3rem;

  ${breakpoint.gt(
    'md',
  )`text-align: right; margin-right: 10rem; margin-bottom: revert;`}
`
