import styled from '@emotion/styled'
import { ThemePallettes } from '@styles/99-themes/theme-pallettes'
import { themes } from '@styles/99-themes'

const theme = (props: { pageTheme?: keyof ThemePallettes }) => `
  ${themes(props.pageTheme || 'theme_default')}
`

export const StyledMain = styled.main`
  ${theme}
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  opacity: 0;
  animation: opacityTransition2 250ms ease-out forwards;
  @keyframes opacityTransition2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
